import * as React from "react"
//import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/ui/layout"
//import Seo from "../components/ui/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <h1>Privacy Policy</h1>
    <p>Coming Soon></p>
    
    
  </Layout>
)

export default PrivacyPolicyPage